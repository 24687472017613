<template>
  <section class="admin-orders">
    <v-container class="custom-container">
      <div class="textbox">
        <div class="left">
          <h1>{{ $t('langkey.orders') }}</h1>
          <p class="description">
            {{ $t('langkey.admin-order-description') }}
          </p>
        </div>
        <div class="right">
          <div
            v-click-outside="hideSearchBar"
            class="search-wrapper"
          >
            <v-btn
              v-if="!search"
              icon
              plain
              class="search__icon"
              @click="onSearch"
            >
              <icon-search size="18" />
            </v-btn>
            <v-btn
              v-else
              icon
              plain
              class="clear__icon"
              @click="clearSearch"
            >
              <icon-close color="var(--theme-gray-4)" />
            </v-btn>
            <input
              v-show="showSearchBar"
              ref="searchBar"
              v-model="search"
              type="search"
              class="search-primary"
              :placeholder="`${$t('langkey.search')}`"
            >
          </div>
        </div>
      </div>

      <v-card
        rounded="sm"
        class="orders"
      >
        <v-data-table
          class="primary-style"
          :headers="tableFieldOrders"
          :items="tableItems"
          :loading="loading"
          show-expand
          :expanded="expanded"
          :item-class="rowClass"
          sort-by="id"
          sort-desc
          :custom-sort="customSort"
          hide-default-footer
          disable-pagination
        >
          <template
            v-for="(field, index) in tableFieldOrders"
            v-slot:[`header.${field.value}`]="{ header }"
          >
            <div
              :key="`${field.value}${index}`"
              class="d-flex align-center justify-start"
            >
              <span>{{ $t(header.text) }}</span>
              <div class="icon__sort" />
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="order-status-wrapper">
              <order-status
                :item="item"
                hide-text
              />
            </div>
          </template>
          <template v-slot:[`item.created`]="{ item }">
            <div class="date-wrapper">
              <span>{{ getDay(item.created) }}</span>
              <span class="dot" />
              <span>{{ getTime(item.created) }}</span>
            </div>
          </template>
          <template v-slot:[`item.address`]="{ item }">
            <div class="address-wrapper">
              <span class="address-name">{{ item.deliveryAddress.name }}</span>
              <span>{{ `${$t('langkey.delivery-address')} ${getAddress(item.deliveryAddress)}` }}</span>
            </div>
          </template>
          <template v-slot:[`item.supplier`]="{ item }">
            {{ item.supplier.name }}
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              :class="`order-${item.id} ${rowClass(item) || ''}`"
            >
              <ul>
                <li
                  v-for="(article, index) in item.articles"
                  :key="`${article.id}${index}`"
                >
                  <span
                    v-if="article.type === 'ShipmentCartArticle'"
                    class="shipping-costs"
                  >
                    {{ $t('langkey.shipping-costs') }}
                  </span>
                  <span
                    v-else
                    class="description"
                  >
                    <div
                      v-if="item.parcels.length > 0"
                      class="tracking"
                    >
                      <a
                        :href="item.parcels[item.parcels.length-1].shipmentTrackingUrl"
                        target="_blank"
                        rel="nofollow"
                      >
                        <img
                          class="courier-logo"
                          :src="item.parcels[item.parcels.length-1].courier.logo"
                        >
                      </a>
                    </div>
                    <div
                      v-else
                      class="tracking"
                    >
                      -
                    </div>
                    <span>{{ `${article.amount} x ${article.description}` }}</span>
                  </span>
                  <span class="list-price">{{ article.price | price }}</span>
                  <span class="total-price">{{ (article.price * article.amount) | price }}</span>
                  <v-btn
                    plain
                    depressed
                    class="contact-supplier__button icon"
                    @click="onClickContactSupplier(item)"
                  >
                    {{ $t('langkey.contact-supplier') }} <icon-caret color="var(--theme-primary)" />
                  </v-btn>
                </li>
                <li>
                  <div
                    v-if="item.parcels.length > 0"
                    class="tracking-number"
                  >
                    <p
                      v-for="(parcel, index) in item.parcels"
                      :key="`parcelTracking${index}`"
                    >
                      {{ parcel.courier.name }}
                      <a
                        :href="parcel.shipmentTrackingUrl"
                        target="_blank"
                        rel="nofollow"
                      >
                        {{ parcel.trackingNumber }}
                      </a>
                    </p>
                  </div>
                  <span class="order-value">{{ $t('langkey.order-value') }} :</span>
                  <span class="sum">{{ getSum(item.articles) | price }}</span>
                  <span class="invoice-pdf">
                    <download-invoice
                      v-for="(id, number) in item.invoicePdfUrls"
                      :id="id"
                      :key="number"
                      :number="number"
                    />
                  </span>
                </li>
                <li v-if="item.shipmentArticle">
                  <span class="shipping-costs">
                    {{ $t('langkey.shipping-costs') }}
                  </span>
                  <span class="list-price">{{ item.shipmentArticle.price | price }}</span>
                  <span class="total-price">{{ (item.shipmentArticle.price * item.shipmentArticle.amount) | price }}</span>
                </li>
              </ul>
            </td>
          </template>
          <template v-slot:[`body.append`]>
            <tr v-if="isLoadingPagination">
              <td
                :colspan="tableFieldOrders.length"
                class="text-center"
              >
                <v-progress-circular
                  indeterminate
                  color="accent"
                />
              </td>
            </tr>
            <tr v-else-if="isNoMorePaginationResult">
              <td
                :colspan="tableFieldOrders.length"
                class="text-center"
              >
                {{ $t('langkey.no-more-result') }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <!-- langkey.contact-supplier
        langkey.shipment
        langkey.order-value -->
      </v-card>

      <status-legend />
    </v-container>
  </section>
</template>

<style lang="scss">
@import "@/styles/pages/admin-orders.scss";
</style>

<script>
import StatusLegend from '@/components/StatusLegend';
import OrderStatus from '@/components/OrderStatus';
import IconCaret from '@/components/icons/IconCaret';
import {tableFieldOrders} from './configs.js';
import {mapState} from 'vuex';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import DownloadInvoice from '@/components/DownloadInvoice';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  components: {
    DownloadInvoice,
    StatusLegend,
    OrderStatus,
    IconCaret,
  },
  data () {
    return {
      search: '',
      showSearchBar: false,
      loading: false,
      expanded: [],
      orderID: 0,
      isScrolled: false,
      pagination: 1,
      isLoadingPagination: false,
      isNoMorePaginationResult: false,
    };
  },
  computed: {
    ...mapState({
      orderItems: (state) => state.order.orderItems,
    }),
    tableFieldOrders() {
      return tableFieldOrders;
    },
    tableItems() {
      if (this.search) {
        return this.orderItems.filter((item) => {
          const foundArticle = item.articles.find((article) => {
            return article.description.toLowerCase().includes(this.search.toLowerCase()) || article.ipc === this.search;
          });
          return this.getDay(item.created).toLowerCase().includes(this.search) ||
          item.id === Number(this.search) ||
          item.invoiceNumber === this.search ||
          item.articles[0].comment.includes(this.search.toLowerCase()) ||
          item.trackinNumber === this.search ||
          item.supplier.name.toLowerCase().includes(this.search.toLowerCase()) ||
          foundArticle;
        }).slice().map((item) => {
          return {
            ...item,
            articles: item.articles.slice().filter((articleItem) => articleItem.type !== 'ShipmentCartArticle'),
            note: item.articles[0].comment,
            shipmentArticle: item.articles.find((articleItem) => articleItem.type === 'ShipmentCartArticle'),
          };
        });
      } else {
        return this.orderItems.slice()
          .map((item) => {
            return {
              ...item,
              articles: item.articles.slice().filter((articleItem) => articleItem.type !== 'ShipmentCartArticle'),
              note: item.articles[0].comment,
              shipmentArticle: item.articles.find((articleItem) => articleItem.type === 'ShipmentCartArticle'),
            };
          });
      }
    },
  },
  created() {
    const { orderID, orderIndex } = this.$route.query;
    let initMaxResult;
    if (orderID) {
      this.orderID = Number(orderID);
    }
    if (orderIndex) {
      initMaxResult = Math.ceil((Number(orderIndex) + 1) / 10) * 10;
      this.pagination = initMaxResult / 10;
    }

    this.loading = true;
    this.$store.dispatch('order/getOrder', {
      maxResult: initMaxResult || 10,
      page: 1,
      sortField: 'id',
      sortDirection: 'DESC',
    })
      .then(() => {
        this.loading = false;
        this.expanded = this.orderItems;
      });
  },
  mounted() {
    window.addEventListener('scroll', this.handleTableScroll);
  },
  updated() { //use this for autoscroll after everything is fully rendered
    if (this.orderID && !this.isScrolled) {
      const orderPosition = this.$el.getElementsByClassName(`order-${this.orderID}`)[0];
      const orderRow = document.getElementsByClassName(`order-${this.orderID}-row`)[0];
      const orderRowExpand = document.getElementsByClassName(`order-${this.orderID}`)[0];
      if (orderRow && orderRowExpand) {
        orderRow.style.backgroundColor = 'aliceblue';
        orderRowExpand.style.backgroundColor = 'aliceblue';
      }
      if (orderPosition) {
        const offsetTop = orderPosition.offsetTop;
        window.scrollTo({
          top: offsetTop + 132, //132 is navigation bar height
          behavior: 'smooth',
        });
        this.isScrolled = true;
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleTableScroll);
  },
  methods: {
    onSearch() {
      if (this.search === '') {
        this.showSearchBar = !this.showSearchBar;
        this.$nextTick(() => {
          this.$refs.searchBar.focus();
        });
      }
    },
    hideSearchBar() {
      if (this.search === '') {
        this.showSearchBar = false;
      }
    },
    clearSearch() {
      this.search = '';
      this.orderID = 0;
    },
    getDay(date) {
      return dayjs(date).tz('Europe/Berlin').format('DD MMM YYYY');
    },
    getTime(date) {
      return dayjs(date).tz('Europe/Berlin').format('HH : mm');
    },
    rowClass(item) {
      let index = this.orderItems.indexOf(item);
      if (this.orderID && item.id === this.orderID) {
        return `order-${item.id}-row`;
      }
      return index % 2 === 0 ? 'even-class': null;
    },
    getAddress(address) {
      let city = address.city || '';
      let country = address.country || '';
      return `${city} ${country}`;
    },
    getSum(articles) {
      let result;
      result = articles.reduce((acc, cur) => {
        return acc + (cur.price * cur.amount);
      }, 0);
      return result;
    },
    onClickContactSupplier(item) {
      const email = item.supplier.contactMail;
      window.location.href = 'mailto:'+email;
    },
    customSort(items, sortBy, isDesc) {
      const prop = sortBy[0];
      if (sortBy.length === 0 && isDesc.length === 0) { //no sorting, default list
        return items;
      } else if (prop === 'supplier') {
        items.sort((a, b) => {
          const currentValue = a[prop].name ? a[prop].name.toLowerCase() : '';
          const nextValue = b[prop].name ? b[prop].name.toLowerCase() : '';
          if (isDesc[0]) {
            if (currentValue < nextValue) {
              return 1;
            }
            if (currentValue > nextValue) {
              return -1;
            }
            return 0;
          } else {
            if (currentValue > nextValue) {
              return 1;
            }
            if (currentValue < nextValue) {
              return -1;
            }
            return 0;
          }
        });
        return items;
      } else if (prop === 'status' || prop === 'id') {
        items.sort((a, b) => {
          const currentValue = a[prop] ? a[prop] : -1;
          const nextValue = b[prop] ? b[prop] : -1;
          if (isDesc[0]) {
            if (currentValue < nextValue) {
              return 1;
            }
            if (currentValue > nextValue) {
              return -1;
            }
            return 0;
          } else {
            if (currentValue > nextValue) {
              return 1;
            }
            if (currentValue < nextValue) {
              return -1;
            }
            return 0;
          }
        });
        return items;
      } else if (prop === 'created') {
        items.sort((a, b) => {
          const currentValue = a[prop] ? new Date(a[prop]) : null;
          const nextValue = b[prop] ? new Date(b[prop]) : null;
          if (isDesc[0]) {
            if (currentValue.getTime() < nextValue.getTime()) {
              return 1;
            }
            if (currentValue.getTime() > nextValue.getTime()) {
              return -1;
            }
            return 0;
          } else {
            if (currentValue.getTime() > nextValue.getTime()) {
              return 1;
            }
            if (currentValue.getTime() < nextValue.getTime()) {
              return -1;
            }
            return 0;
          }
        });
        return items;
      } else if (prop === 'address') {
        items.sort((a, b) => {
          const currentValue = a[prop].city ? a[prop].city.toLowerCase() : '';
          const nextValue = b[prop].city ? b[prop].city.toLowerCase() : '';
          if (isDesc[0]) {
            if (currentValue < nextValue) {
              return 1;
            }
            if (currentValue > nextValue) {
              return -1;
            }
            return 0;
          } else {
            if (currentValue > nextValue) {
              return 1;
            }
            if (currentValue < nextValue) {
              return -1;
            }
            return 0;
          }
        });
        items.sort((a, b) => {
          const currentValue = a[prop].country ? a[prop].country.toLowerCase() : '';
          const nextValue = b[prop].country ? b[prop].country.toLowerCase() : '';
          if (isDesc[0]) {
            if (currentValue < nextValue) {
              return 1;
            }
            if (currentValue > nextValue) {
              return -1;
            }
            return 0;
          } else {
            if (currentValue > nextValue) {
              return 1;
            }
            if (currentValue < nextValue) {
              return -1;
            }
            return 0;
          }
        });
        return items;
      } else {
        items.sort((a, b) => {
          const currentValue = a[prop] ? a[prop].toLowerCase() : '';
          const nextValue = b[prop] ? b[prop].toLowerCase() : '';
          if (isDesc[0]) {
            if (currentValue < nextValue) {
              return 1;
            }
            if (currentValue > nextValue) {
              return -1;
            }
            return 0;
          } else {
            if (currentValue > nextValue) {
              return 1;
            }
            if (currentValue < nextValue) {
              return -1;
            }
            return 0;
          }
        });
        return items;
      }
    },
    handleTableScroll() {
      if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 2 - 115
          && this.isLoadingPagination === false
          && this.isNoMorePaginationResult === false) {
        this.pagination += 1;
        this.getOrderNextPage();
      }
    },
    async getOrderNextPage() {
      this.isLoadingPagination = true;
      try {
        await this.$store.dispatch('order/getOrderNextPage', {
          maxResult: 10,
          page: this.pagination,
          sortField: 'id',
          sortDirection: 'DESC',
        });
        this.expanded = this.orderItems;
      } catch (err) {
        if (err === 'noResult') {
          this.isNoMorePaginationResult = true;
          this.isLoadingPagination = false;
          return;
        }
        const data = err.response.data;
        const status = err.response.status;
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error tyres',
          message: `<p>${data['error_title']} - ${status}</p>`,
        });
      } finally {
        this.isLoadingPagination = false;
      }
    },
  },
};
</script>
