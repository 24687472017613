<template>
  <div class="status-legend">
    <ul>
      <li>
        <icon-order-status-confirm /> {{ $t('langkey.ordered') }}
      </li>
      <li>
        <icon-order-status-sent /> {{ $t('langkey.sent') }}
      </li>
      <li>
        <icon-order-status-invoice /> {{ $t('langkey.invoices') }}
      </li>
      <li>
        <icon-order-status-cancel /> {{ $t('langkey.canceled') }}
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
@import '@/styles/components/status-legend.scss';
</style>

<script>
import IconOrderStatusConfirm from '@/components/icons/IconOrderStatusConfirm';
import IconOrderStatusSent from '@/components/icons/IconOrderStatusSent';
import IconOrderStatusInvoice from '@/components/icons/IconOrderStatusInvoice';
import IconOrderStatusCancel from '@/components/icons/IconOrderStatusCancel';

export default {
  components: {
    IconOrderStatusConfirm,
    IconOrderStatusSent,
    IconOrderStatusInvoice,
    IconOrderStatusCancel,
  },
};
</script>